/* eslint eqeqeq: 0 */

import { useTheme } from "@emotion/react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  Dialog
} from "@mui/material";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { toast } from "react-toastify";
import api from "../../../API/Fetch_data_Api";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../redux/UserData";
import { handleAuth } from "../../../redux/authentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from 'react-router-dom';
const RegisterUser = ({ mobile, handleSignUp }) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.UserData);

  const [loader, setLoader] = useState(false);

//
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const username = localStorage.getItem("currentuser");
    const email = localStorage.getItem("currentemail");
    if (username) setUsername(username);
    if (email) setEmail(email);
  }, []);


  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const submite = () => {
    setLoader(true);

    const formData = {
        first_name:firstName,
        last_name:lastName,
        username:username,
        email:email,
        phone:phone,
        password:password,
        country_code: "+63"
    }

    if (email !== "" && !validateEmail(email)) {
      setLoader(false);
      toast.error("Invalid email Address");
      return;
    }


    api
      .manageUser(formData)
      .then((response) => response)
      .then((result) => {

        if (result.error == false) {
          setLoader(false);
          toast.success(result.message);
          const temp = { ...profile };
          temp.data = result.data;
          dispatch(setProfile(temp));
          dispatch(handleAuth(true));
        } else {
          Object.values(result.message).forEach((key) => {
            toast.error(key);
          });
        }

        navigate("/")
      })

      .catch((error) => console.log("error", error));
  };


  // if user name already set and user want to modify that

  return (
        <div>
        <Box display={"flex"}>
            <Typography
                fontWeight={"bolder"}
                marginRight={"auto"}
                color={theme.palette.color.navLink}
            >
                Create Account
            </Typography>
            {
                <ClearIcon
                    onClick={handleSignUp}
                    sx={{ color: theme.palette.color.navLink }}
                />
            }
        </Box>
            <br />
     <form>
       <Box display={"flex"} flexDirection={"column"} gap={2}>

       <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>Username</FormLabel>
           <TextField
             id="username"
             placeholder={"Enter Username"}
             size="small"
             onChange={(e) => setUsername(e.target.value)}
             fullWidth
             variant="outlined"
             name="name"
             required
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>




       <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>First name</FormLabel>
           <TextField
             id="firstName"
             placeholder={"Enter Last name"}
             size="small"
             onChange={(e) => setFirstName(e.target.value)}
             fullWidth
             variant="outlined"
             name="name"
             required
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>


         <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>Last name</FormLabel>
           <TextField
             id="lastName"
             placeholder={"Enter Last name"}
             size="small"
             onChange={(e) => setLastName(e.target.value)}
             fullWidth
             variant="outlined"
             name="name"
             required
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>

         <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>{t("email")}</FormLabel>

           <TextField
             id="editEmail"
             placeholder={t("enter_email")}
             size="small"
             fullWidth
             variant="outlined"
             value={email}
             onChange={(e) => setEmail(e.target.value)}
             name="email"
             type="email"
             required
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>

         <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>{t("phone")}</FormLabel>

           <TextField
             id="editPhone"
             placeholder="Enter Phone Number"
             onChange={(e) => setPhone(e.target.value) }
             size="small"
             fullWidth
             required
             variant="outlined"
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>


         <Box sx={{ borderRadius: "10px" }}>
           <FormLabel sx={{ fontWeight: "bolder" }}>Password</FormLabel>

           <TextField
             id="password"
             placeholder="Enter Password"
             type="password"
             onChange={(e) => setPassword(e.target.value) }
             size="small"
             fullWidth
             required
             // disabled
             variant="outlined"
             sx={{
               background: theme.palette.background.input,
               borderRadius: "10px",
             }}
           />
         </Box>

         <Button
           variant="contained"
           size="medium"
           fullWidth
           onClick={submite}
           disabled={loader}
           sx={{
             backgroundColor: theme.palette.background.buttonColor,
             "&:hover": {
               backgroundColor: theme.palette.background.buttonColor,
             },
           }}
           startIcon={
             loader == true ? <FontAwesomeIcon icon={faSpinner} spin /> : ""
           }
         >
           {t("sign_up")}
         </Button>
       </Box>
     </form>
   </div>
)}


export default RegisterUser;
